<template>
    <div class="auth-wrapper auth-v2 bg-white">
      <b-row align-h="center" class="auth-inner m-0">
  
        <!-- Left Text-->
        <!-- <b-col
          lg="6"
          class="d-none d-lg-flex align-items-center"
          :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`"
        >
          <div class="left--side__wrapper">
            <div class="wrapper__side">
              <div>
                <a href="https://toqoo.id/">
                  <img class="mb-1" src="@/assets/images/toqoo-green.svg" alt="logo" width="95" role="button">
                </a>
                <hr
                  class="w-25 m-0"
                  style="border: 1px solid #E79242;"
                >
                <div class="mt-3">
                  <h6
                    class="size14 fw-bold-400"
                    style="line-height: 23px;"
                  >
                    Khusus di desain untuk manajemen Toko Bangunan. <br>
                    Praktis, mudah dan lengkap.
                  </h6>
                </div>
              </div>
              <div>
                <h5 class="size14 fw-bold-400 mb-1">
                  Coba toqoo ?
                </h5>
                <h5 class="size16 fw-bold-600" @click="$router.push({ name: 'register' })" role="button">
                  Daftar Sekarang
                </h5>
              </div>
            </div>
          </div>
        </b-col> -->
        <!-- /Left Text-->
  
        <!-- Login-->
        <b-col
          lg="6"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="10"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="fw-bold-800 mb-1 text-dark size20"
            >
              👋🏼 Halo, Selamat Datang di Supplier Dashboard
            </b-card-title>
            <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-md-5 mb-0">
              Login terlebih dahulu untuk bisa akses web toqoo
            </b-card-text>
  
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group class="custom__form--input mb-2">
                  <label for="email">Email / Handphone <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="userEmail"
                      name="login-email"
                      class="custom__input"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Email"
                      autofocus
                      style="border-top-right-radius: 8px;border-bottom-right-radius: 8px;"
                    />
                    <!-- <small class="text-danger pt-1">{{ errors[0] }}</small> -->
                  </validation-provider>
                </b-form-group>
  
                <!-- forgot password -->
                <b-form-group class="custom__form--input">
                  <label for="login-password">Password <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        class="form-control-merge custom__input"
                        :state="errors.length > 0 ? false:null"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>
                </b-form-group>
  
                <div class="mb-3 text-right">
                  <router-link class="text-primary fw-bold-800 size16" id="button--forgot__password" to="/lupa-password">
                    Lupa Password
                  </router-link>
                </div>
  
                <!-- submit buttons -->
                <b-button
                  id="button--login"
                  type="submit"
                  variant="secondary"
                  block
                  class="size14 py-1 border-8 d-flex justify-content-center align-items-center"
                  @click="$router.push({ name: 'supplier-dashboard.index' })"
                  >
                  <!-- :disabled="(isLoading || userEmail == '' || password == '' || buttonDisabled)" -->
                  <!-- @click="validationForm" -->
                  <div
                    v-if="isLoading == true"
                    class="d-flex justify-content-center mr-1"
                  >
                    <b-spinner
                      small
                      label="Loading..."
                    />
                  </div>
                  Login
                </b-button>
              </b-form>
            </validation-observer>
  
            <b-card-text class="text-center mt-2">
              <span>Jika ada kendala terkait login bisa </span>
              <a
                class="text-dark text-darken-6"
                :href="'https://wa.me/6281807070900'"
                target="_blank"
              >
                &nbsp;hubungi admin
              </a>
            </b-card-text>
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
      <!-- <div class="social-media">
        <div class="d-flex justify-content-end">
          <a href="https://www.instagram.com/toqooid/" target="_blank" class="mr-25 pr-25">
            <img width="44" src="@/assets/images/icons/instagram.png" alt="instagram-logo">
          </a>
          <a href="https://www.tiktok.com/@toqooindonesia?_op=1&_r=1&_t=8czXipzyH7C" target="_blank" class="mr-25 pr-25">
            <img width="44" src="@/assets/images/icons/tiktok.png" alt="tiktok-logo">
          </a>
          <a href="https://www.facebook.com/profile.php?id=100091844745102&mibextid=ZbWKwL" target="_blank" class="mr-25 pr-25">
            <img width="44" src="@/assets/images/icons/facebook.png" alt="facebook-logo">
          </a>
          <a href="https://youtube.com/@ToqooID" target="_blank" class="d-flex justify-content-center align-items-center">
            <img width="54" src="@/assets/images/icons/youtube.png" alt="youtube-logo">
          </a>
        </div>
      </div> -->
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner, BInputGroupAppend,
  } from 'bootstrap-vue'
  import { isEmail } from '@/auth/utils'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import useJwt from '@/auth/jwt/useJwt'
  
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroup,
      BCardText,
      BCardTitle,
      BForm,
      BButton,
      ValidationProvider,
      ValidationObserver,
      BSpinner,
      BInputGroupAppend,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        status: '',
        password: '',
        userEmail: '',
        phone: '',
        sideImg: require('@/assets/images/pages/login_background-2.png'),
        // validation rulesimport store from '@/store/index'
        required,
        email,
        isLoading: false,
        buttonDisabled: false,
      }
    },
    setup() {
      return {
        isEmail,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    methods: {
      validationForm() {
        this.$refs.loginValidation.validate().then(success => {
          this.isLoading = true
          if (success) {
            this.buttonDisabled = true;
            const formEmail = {
              email: this.userEmail,
              password: this.password,
            }
            const formHp = {
              phone: this.userEmail,
              password: this.password,
            }
  
            const digit0 = String(formHp.phone).charAt(0)
            const digit1 = Number(digit0)
  
            if (digit1 === 0) {
              // phone = phone.substring(1)
              formHp.phone = `62${formHp.phone.substring(1)}`
            }
  
            useJwt.login(isEmail(this.userEmail) ? formEmail : formHp)
              .then(response => {
                const userPermission = JSON.stringify(response.data.data.permission)
                localStorage.setItem('userPermission', userPermission)
                const { token } = response.data.data
                useJwt.setToken(token)
                if (response.data.data) {
                  const userData = JSON.stringify(response.data.data.user)
                  localStorage.setItem('userData', userData)
                  if (response.data.data.user.phone_verified_at == null) {
                    localStorage.setItem('operator_phone', response.data.data.user.phone)
                    localStorage.setItem('otp_type', 'register')
                    localStorage.setItem('from', 'login')
                    // localStorage.setItem('expired_at', userData.phone)
                  }
                  this.isLoading = false
                  this.buttonDisabled = false;
                  window.location.reload();
                  this.$router.push('/')
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Access Denied!',
                      icon: 'DangerIcon',
                      variant: 'danger',
                      text: 'You dont have access to this area!',
                    },
                  })
                  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                  this.isLoading = false
                  this.buttonDisabled = false;
                  this.$router.push({ name: 'login' })
                }
              }).catch(() => {
                this.buttonDisabled = false;
                this.isLoading = false
              })
            // setTimeout(() => {
            //   this.isLoading = false
            // }, 500)
          }
        })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  
  <style lang="scss" scoped>
  .social-media {
    position: absolute;
    bottom: 15px;
    right: 15px;
  
    z-index: 1000;
  }
  .image__cover--login {
    border-radius: 0px 32px 32px 0px;
    object-fit: cover;
    height: 100vh;
  }
  .left--side__wrapper {
    position: absolute;
    left: 45%;
    top: 15%;
    transform: translate(-50%);
    .wrapper__side {
      display: flex;
      flex-direction: column;
      height: 70vh;
      justify-content: space-between;
  
      h1 {
        font-family: 'Medula One', sans-serif !important;
        font-size: 50px;
      }
  
      h5, h6 {
        color: #fff;
        font-family: 'Be Vietnam Pro', sans-serif !important;
      }
    }
  }
  </style>
  